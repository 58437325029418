module.exports = [{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134251684-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1080},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/render/project/src/src/cms/cms.js"},
    },{
      plugin: require('/opt/render/project/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
